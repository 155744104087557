import { TableRow, TableCell, Skeleton } from "@mui/material";

function NIDListSkeletonComponent() {


    let items = [];

    for (let i = 0; i < 6; i++) {
        items.push(
            <TableRow key={i} sx={{'&:hover': {backgroundColor: "#fff"}}}>
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/></TableCell>
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/></TableCell>
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/></TableCell>
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/></TableCell>
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/></TableCell>
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/></TableCell>
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/></TableCell>
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/></TableCell>
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/></TableCell>
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/></TableCell>
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/></TableCell>
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/></TableCell>
            </TableRow>
        )
    }

    return <>{items}</>
}



export default  NIDListSkeletonComponent;