
import React, {useState, useEffect } from "react";
import {Box, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography, List, ListItem, IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { format } from "date-fns";

const DetailViewDialogComponent = (props) => {

    const {viewDetails, setViewDetails, list} = props;

    const handleClose = () => {
        setViewDetails(false)
    }

    const auditDetailsColumnOne = [
        {label: 'ID', value: list?.id},
        {label: 'Last Name', value: list?.lastname},
        {label: 'First Name', value: list?.firstname},
        {label: 'Middle Name', value: list?.middlename},
        
    ]

    const auditDetailsColumnTwo = [
        {label: 'Gender', value: list?.sex},
        {label: 'Date of Birth', value: list?.dob},
        {label: 'Place of Birth', value: list?.pob},
        {label: 'Created Time', value: list && format(new Date(list?.created_at), "PPpp")}
    ]

    return (
        <Dialog open={viewDetails} maxWidth={'md'} fullWidth={'md'} onClose={handleClose} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5,}}}>
            <DialogTitle sx={{display: "flex", alignItems:'center'}}>
                <Grid container>
                    <Grid item xs={6} sx={{alignSelf:'center', display:'flex', alignItems:'center'}}>
                        <Typography sx={{fontSize: 18, fontWeight: 600, mr: 1}}>
                            PCN:
                        </Typography>
                        <Typography sx={{fontSize: 18, fontWeight: 600, color:'#0178f2'}}>
                            {list?.pcn}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign:'end', alignSelf:'center', }} >
                        <IconButton onClick={handleClose} sx={{color: '#0178f2', }} >
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Grid container spacing={1}>
                            {auditDetailsColumnOne.map((label)=>{
                                return(
                                    <Grid item xs={12} sx={{display:'flex', alignItems:'center'}}>
                                        <Grid item xs={4}>
                                            <Typography sx={{mr: 1, fontWeight: 600, fontSize: 14}} >
                                                {label.label}:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Typography sx={{fontWeight: 600, fontSize: 14, color: 'gray'}}>
                                                {label.value}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                        
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={1}>
                            {auditDetailsColumnTwo.map((label)=>{
                                return(
                                    <Grid item xs={12} sx={{display:'flex', alignItems:'center'}}>
                                        <Grid item xs={4}>
                                            <Typography sx={{mr: 1, fontWeight: 600, fontSize: 14}} >
                                                {label.label}:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Typography sx={{fontWeight: 600, fontSize: 14, color: 'gray'}}>
                                                {label.value}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4} >
                        <Grid container>
                            <Grid item xs={12} sx={{border: '1px solid black', borderRadius: 2}}>
                                <img src={list?.front_id} style={{height: 150, objectFit:'contain', width: '100%'}}/>
                            </Grid>
                            <Grid item xs={12} >
                                <Typography sx={{textAlign:'center', fontWeight:'bold'}}>Front</Typography>
                            </Grid>
                            
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Grid container >
                            <Grid item xs={12} sx={{border: '1px solid black', borderRadius: 2}}>
                                <img src={list?.back_id} style={{height: 150, objectFit:'contain', width: '100%'}}/>
                            </Grid>
                            <Grid item xs={12} >
                                <Typography sx={{textAlign:'center', fontWeight:'bold'}}>Back</Typography>
                            </Grid>
                            
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Grid container >
                            <Grid item xs={12} sx={{border: '1px solid black', borderRadius: 2}}>
                                <img src={list?.selfie} style={{height: 150, objectFit:'contain', width: '100%'}}/>
                            </Grid>
                            <Grid item xs={12} >
                                <Typography sx={{textAlign:'center', fontWeight:'bold'}}>Selfie</Typography>
                            </Grid>
                            
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
          

        </Dialog>
    )
}

export default DetailViewDialogComponent