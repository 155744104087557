import { useState } from "react"
import AuditService from "../services/api/audit"
import { useNavigate } from "react-router-dom"


const useAudit = () =>{

    const [retrieve, setRetrieve] = useState(null)
    const [searchPcnList, setSearchPcnList] = useState(null)
    const [loading, setIsloading] = useState(false)
    const [searchLoading, setSearchLoading] = useState(false)
    const [pagination, setPagination] = useState(0);
    const navigate = useNavigate();

    var limit = 10

    const retrieveAudit = (page) => {
        setIsloading(true)
        AuditService.retrieveAudit(page, limit).then((res)=>{
            console.log('retrieve audit res', res)
            setIsloading(false)
            if (res.data.success) {
                setRetrieve(res.data.data)

                if (res?.data?.data?.length === 0) {
                    setPagination(0);
                } else {
                    var last_page = Math.ceil(res?.data?.limit);
                    setPagination(last_page);
                }
            }
            
        }).catch((err)=>{
            setIsloading(false)
        })
    }

    const searchPCN = (pcn, page) => {
        setSearchLoading(true)
        AuditService.searchPCN(pcn, page, limit).then((res)=>{
            console.log('search pcn res', res)
            setSearchLoading(false)
            if (res.data.success) {
                setSearchPcnList(res.data.data)

                if (res?.data?.data?.length === 0) {
                    setPagination(0);
                } else {
                    var last_page = Math.ceil(res?.data?.limit);
                    setPagination(last_page);
                }
            }
            
        }).catch((err)=>{
            setSearchLoading(false)
        })
    }

    return{retrieveAudit, loading, pagination, retrieve, searchLoading, searchPCN, searchPcnList}
}

export default useAudit