import React, {useState} from "react";
import {Box, TableCell, TableRow, Tooltip, Typography, IconButton} from "@mui/material";
import {format} from 'date-fns';
import BackViewDialogComponent from "./dialog/back-view-dialog.component";
import FrontViewDialogComponent from "./dialog/front-view-dialog.component";
import ViewSelfieDialogComponent from "./dialog/selfie-dialog.component";
import DetailViewDialogComponent from "./dialog/detail-view-dialog.component";
import { useSnackbar } from "notistack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function NIDListComponent (props) {

    const {list, index, } = props;
    const {enqueueSnackbar} = useSnackbar();
    const [viewBackIdDialog, setViewBackIdDialog] = useState(false)
    const [viewFrontIdDialog, setViewFrontIdDialog] = useState(false)
    const [viewSelfieDialog, setViewSelfieDialog] = useState(false)
    const [viewDetails, setViewDetails] = useState(false)

    const handleOpenBackIdViewDialog = () => {
        setViewBackIdDialog(true)
    }

    const handleOpenFrontIdViewDialog = () => {
        setViewFrontIdDialog(true)
    }

    const handleOpenSelfieViewDialog = () => {
        setViewSelfieDialog(true)
    }

    const handleOpenViewDetailsDialog = () => {
        setViewDetails(true)
    }

    const handleCopyToClipboard = (e, name, reference) => {
        navigator.clipboard.writeText(reference);

        if (name == "pcn") {
            enqueueSnackbar("Successfully copied PCN", { 
                variant: "success",
                autoHideDuration: 3000
            });
        } 
    }

    console.log('LIST COMPONENT HERE', list)

    return(
        <TableRow key={index} sx={{'&:last-child td, &:last-child th': {border: 0}}}>  
            <TableCell onClick={handleOpenViewDetailsDialog} component="th" scope="row" sx={{color: "#0178f2", cursor: "pointer"}}>
                {list?.id}
            </TableCell>
            <TableCell component="th" scope="row" sx={{cursor: "pointer"}}>
                {list?.pcn}
                <IconButton onClick={(e) => handleCopyToClipboard(e, "pcn", list?.pcn)}>
                    <ContentCopyIcon sx={{alignSelf:'center', width: 18, height: 18, color: "#0178f2"}}/>
                </IconButton>
            </TableCell>
            <TableCell component="th" scope="row" sx={{cursor: "pointer"}}>
                {list?.lastname}
            </TableCell>
            <TableCell component="th" scope="row" sx={{cursor: "pointer"}}>
                {list?.firstname}
            </TableCell>
            <TableCell component="th" scope="row" sx={{cursor: "pointer"}}>
                {list?.middlename}
            </TableCell>
            <TableCell component="th" scope="row" sx={{cursor: "pointer"}}>
                {list?.sex}
            </TableCell>
            <TableCell component="th" scope="row" sx={{cursor: "pointer"}}>
                {list?.dob}
            </TableCell>
            <TableCell component="th" scope="row" sx={{cursor: "pointer"}}>
                {list?.pob}
            </TableCell>
            <TableCell onClick={handleOpenFrontIdViewDialog} component="th" scope="row" sx={{cursor: "pointer"}}>
               <img src={list?.front_id} style={{height: 100, objectFit:'contain', width:'100%'}}/>
            </TableCell>
            <TableCell onClick={handleOpenBackIdViewDialog} component="th" scope="row" sx={{cursor: "pointer"}}>
                <img src={list?.back_id} style={{height: 100, objectFit:'contain', width:'100%'}}/>
            </TableCell>
            <TableCell onClick={handleOpenSelfieViewDialog} component="th" scope="row" sx={{cursor: "pointer"}}>
                <img src={list?.selfie} style={{height: 100, objectFit:'contain', width:'100%'}}/>
            </TableCell>
            <TableCell component="th" scope="row" sx={{cursor: "pointer"}}>
                {format(new Date(list?.created_at), "PPpp")}
            </TableCell>

            <FrontViewDialogComponent viewFrontIdDialog={viewFrontIdDialog} setViewFrontIdDialog={setViewFrontIdDialog} list={list} />
            <BackViewDialogComponent viewBackIdDialog={viewBackIdDialog} setViewBackIdDialog={setViewBackIdDialog} list={list} />
            <ViewSelfieDialogComponent viewSelfieDialog={viewSelfieDialog} setViewSelfieDialog={setViewSelfieDialog} list={list} />
            <DetailViewDialogComponent viewDetails={viewDetails} setViewDetails={setViewDetails} list={list} />

        </TableRow>
    )
}

export default NIDListComponent