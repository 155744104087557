import {Box, Grid, Typography, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Paper, Pagination, TextField, IconButton, InputAdornment, Button, } from '@mui/material'
import AuditService from '../services/api/audit';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import NIDListComponent from '../components/nid-audit/nid-list.component';
import useAudit from '../hooks/useAudit';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import AuthService from '../services/auth';
import NIDListSkeletonComponent from '../components/nid-audit/skeleton/nid-list-skeleton.component';

function TableHeader () {
    const tablecell = [
        {label : 'ID'},
        {label : 'PCN'},
        {label : 'Last Name'},
        {label : 'First Name'},
        {label : 'Middle Name'},
        {label : 'Gender'},
        {label : 'Date of Birth'},
        {label : 'Place of Birth'},
        {label : 'Front ID'},
        {label : 'Back ID'},
        {label : 'Selfie'},
        {label : 'Created Time'},
    ];

    return(
        <TableHead>
            <TableRow>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{fontWeight: 700, backgroundColor: "#0178f2", color: "#fff",
                            textAlign: (value.label == "Signature") ? "center" : {}}}
                        >
                            {value.label}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

function NIDAudits () {

    const {retrieveAudit, loading, pagination, retrieve, searchLoading, searchPCN, searchPcnList} = useAudit()

    const [page, setPage] = useState(1);
    const [searchInput, setSearchInput] = useState('');

    const searchItems = (e) => {
        setSearchInput(e.target.value)
    }


    const keyPress = (e) => {
        if (e.keyCode === 13) {
            searchPCN(e.target.value, page);
        }
    }

    const handleClickSearch = (e) => {
        if(searchInput){
            searchPCN(searchInput, page);
        }
        
    }

    const handleChangePagination = (event, page) => {
        setPage(page);
        retrieveAudit(page);
        searchPCN(searchInput, page)
    }

    const handleChangeSearchPagination = (event, page) => {
        setPage(page);
        searchPCN(page);
    }

    useEffect(()=>{
        retrieveAudit(page)
    },[])

    const handleLogout = () => {
        AuthService.logout().then((res) => {
            localStorage.clear();
            window.location.href = "/";
          });
    }

    const getItemAriaLabel = (type, page, selected) => {
        if (type === 'previous') {
          return 'Previous Page';
        }
        if (type === 'next') {
          return 'Next Page';
        }
        return `${selected ? 'Current' : 'Go to'} Page ${page}`;
      };

    return(
        <Box sx={{width:'95%', margin:'auto'}}>
            <Box sx={{textAlign:'end', pt: 1, }}>
                <Button onClick={handleLogout} sx={{fontSize: 12, color: '#0178f2'}}>Logout</Button>
            </Box>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs={6} >
                    <Typography onClick={()=> window.location.reload()} variant='h6' sx={{fontWeight: "bold", color: "#21649e", cursor:'pointer'}}>Audits</Typography>
                </Grid>

                <Grid item xs={6} sx={{textAlign:'end'}}>
                    <TextField variant="outlined" autoComplete="off" 
                        placeholder='Search PCN'
                        defaultValue={searchInput}
                        onChange={searchItems}
                        onKeyDown={keyPress}
                        inputProps={{ style: { padding: '10px' } }}
                        InputProps={{ endAdornment: <InputAdornment position="end"> <IconButton onClick={(e)=> handleClickSearch()} aria-label="toggle password visibility" edge="end"><SearchIcon /></IconButton></InputAdornment>, }}
                    />
                </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper} sx={{border: "1px solid #8080807a", borderRadius: 2, width: "100%"}}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHeader/>

                        {loading ? 
                            <TableBody>
                                <NIDListSkeletonComponent/>
                            </TableBody>
                        :
                            <TableBody>
                                {searchInput?.length > 0 ? (
                                    <>
                                        {(searchPcnList && searchPcnList)?.map((list, index) => (
                                            <NIDListComponent list={list} index={index} handleChangePagination={handleChangePagination} 
                                            loading={loading} page={page} pagination={pagination} />
                                        ))}
                                    </>
                                ): retrieve?.length > 0 ? (
                                    <>
                                        {retrieve?.map((list, index) => (
                                            <NIDListComponent list={list} index={index} handleChangePagination={handleChangePagination} 
                                            loading={loading} page={page} pagination={pagination} />
                                        ))}
                                    </>
                                ) : ( 
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Typography>No records.</Typography>
                                    </Box>
                                 )}

                                {searchInput.length > 0 &&
                                    <>
                                        {(searchPcnList?.length === 0 || searchPcnList === null) &&
                                            <Box sx={{textAlign: 'center' }}>
                                                <Typography>Search result not found.</Typography>
                                            </Box>
                                        }
                                    </>
                                }
                            </TableBody>
                        }
                    </Table>
                </TableContainer>

                {/* {!loading && retrieve && retrieve?.length > 0 && */}
                {/* {retrieve ? */}
                    <Pagination
                        count={pagination} 
                        page={page} 
                        onChange={handleChangePagination} 
                        style={{margin:'auto', width:'fit-content', paddingTop: 30, paddingBottom: 30 }}
                        // disabled={retrieve?.length === 0}
                        hideNextButton={retrieve?.length < 10 || searchPcnList?.length < 10}
                        siblingCount={1}
                        getItemAriaLabel={getItemAriaLabel}
                    />
              
                
                {/* } */}
            </Grid>
            </Grid>
        </Box>
    )
}

export default NIDAudits