import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPostMultipart, HttpPut, HttpPublicGet, CustomHttpPostMultipart, HttpPost} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.nid_verifier_service.subscriptionKey;

const retrieveAudit = (page, limit) => {

    let query_params = {
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.RETRIEVE_AUDITS, query_params, subscriptionKey);
    
    return result;
}

const searchPCN = (pcn, page, limit) => {

    let parameters = {
        pcn: pcn,
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.RETRIEVE_AUDITS, parameters, subscriptionKey);
    
    return result;
}

const AuditService = {
    retrieveAudit: retrieveAudit,
    searchPCN: searchPCN
}

export default AuditService