import LandingScreen from "../screens/LandingScreen";
import NIDAudits from "../screens/NIDAudits";

export const routes = [
    {
        path: "/",
        name: 'LandingScreen',
        element: <LandingScreen />,
        isPrivate: false
    },
    {
        path: "/audits",
        name: 'NIDAudits',
        element: <NIDAudits />,
        isPrivate: true
    },
    
]
