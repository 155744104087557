import "./App.css";
import {BrowserRouter} from "react-router-dom"; 
import {MsalProvider} from "@azure/msal-react";
import AuthService from "./services/auth";
import LandingScreen from "./screens/LandingScreen";
import Routes from "./router";
import {SnackbarProvider} from "notistack";

const App = ({publicClientApp}) => {

  const url = window.location.pathname;
  var isUserLoggedIn = AuthService.isUserLoggedIn();

    return (
        <div>
          <SnackbarProvider>
            <MsalProvider instance={publicClientApp}>
              <BrowserRouter >
                {(isUserLoggedIn) ? 
                  <Routes /> 
                  : 
                  <LandingScreen/>
                }
              </BrowserRouter>
            </MsalProvider>
          </SnackbarProvider>
        </div>
    )
}

export default App