import environment from "../../../environment/environment"

const NID_VERIFIER_SERVICE_BASE_URL = environment.nid_verifier_service.url ;


export default {

   // AUDIT
   RETRIEVE_AUDITS :                   NID_VERIFIER_SERVICE_BASE_URL + '/nid/retrieve/audits',
   

}