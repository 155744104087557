// Add local instance here
// const website_URL = "http://localhost:3000"

// Dev instance
const website_URL = "https://nv2.nextix.co"

const subscriptionKey = "54d74f4376a14385859051cdd79dd11d"

const nidVerifierServiceUrl = "https://ovcodedevapim.ovcode.com/nid-verifier"

export default {


    // BASE URL

    nid_verifier_service : {
        url: nidVerifierServiceUrl,
        subscriptionKey : subscriptionKey,
    },

    // AUTHENTICATION

    msal_url : {
        url: website_URL,
    },

    msal_authConfig : {
        clientId:'29010e68-4ad8-4b08-84e6-d98f8deab463',
        knownAuthorities: ["nextixeid.b2clogin.com"],
        authority:'https://nextixeid.b2clogin.com/tfp/nextixeid.onmicrosoft.com/B2C_1_verifier/',
        postLogoutRedirectUri: website_URL,
    },
    
    b2c_scope : {
        scopes:[
            "https://nextixeid.onmicrosoft.com/29010e68-4ad8-4b08-84e6-d98f8deab463/Verifier"
        ]
    }

};

