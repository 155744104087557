
import React, {useState, useEffect } from "react";
import {Box, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography, List, ListItem, IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const FrontViewDialogComponent = (props) => {

    const {viewFrontIdDialog, setViewFrontIdDialog, list} = props;

    const handleClose = () => {
        setViewFrontIdDialog(false)
    }

    return (
        <Dialog open={viewFrontIdDialog} maxWidth={'md'} fullWidth={'md'} onClose={handleClose} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5,}}}>
            <DialogTitle sx={{display: "flex", alignItems:'center'}}>
                <Grid container>
                    <Grid item xs={6} sx={{alignSelf:'center',}}>
                        <Typography sx={{fontSize: 15, fontWeight: 600}}>
                            Front ID
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign:'end', alignSelf:'center', }} >
                        <IconButton onClick={handleClose} sx={{color: '#0178f2', }} >
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
                
            </DialogTitle>

            <DialogContent>
                <img src={list?.front_id} style={{height: 500, objectFit:'contain', width:'100%'}}/>
            </DialogContent>

        </Dialog>
    )
}

export default FrontViewDialogComponent