import AuthService from "../services/auth"
import { Box, Button, Typography} from "@mui/material"

function LandingScreen () {

    
    const handleLogin = () => {
        AuthService.login().then((res)=>{
            window.location.href="/audits"; 
          })
    
          .catch((error)=>{
            console.log("login error iii", error);
          });
    }

    return(
        <Box sx={{width: '100%', margin:'auto', textAlign:'center'}} >
            <Box sx={{position:'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)'}}> 
                <Typography sx={{fontSize: 50, fontWeight:'bold'}} >
                    National ID Verification<br/>Portal
                </Typography>
                <br/>
                <Button onClick={handleLogin} variant="contained" sx={{width: 100}} > Sign in</Button>
            </Box>
        </Box>
    )
}

export default LandingScreen